<template>
    <v-card width="100%" height="100%" class="rounded-0">
        <v-toolbar color="warning">
            <v-toolbar-title>Debug Panel</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <p>Dirty: <v-chip :color="dirtyColor">{{ isDirty }}</v-chip> </p>
            <h4>initData</h4>
            <json-viewer
                :value="initData"
                :expand-depth=3
                boxed
            />
            <h4>newData</h4>
            <json-viewer
                :value="newData"
                :expand-depth=3
                boxed
            />
            <json-viewer
                :value="error"
                :expand-depth=3
                boxed
            />
        </v-card-text>
    </v-card>
    
</template>

<script>
import {get} from 'vuex-pathify'
import JsonViewer from 'vue-json-viewer'

export default {
    components: {JsonViewer},
    computed: {
        ...get('form', [
            'initData',
            'newData',
            'isDirty'
        ]),
        dirtyColor () {
            return (this.isDirty ? "green" : "red")
        },
        error () {
            if (Object.keys(this.$store.state.incidentsnew.error).length === 0)
                return {}
            return this.$store.state.incidentsnew.error
        }
    }
}
</script>
